<template>
  <div id="app">
    <!-- <el-form>
      <el-button type="primary">按钮啊</el-button>
      <el-button type="danger">按钮啊</el-button>
      <el-button type="warning">按钮啊</el-button>
      <el-button type="info">按钮啊</el-button>
      <el-button type="default">按钮啊</el-button>
      <br/>

      <el-button type="primary" plain>按钮啊</el-button>
      <el-button type="danger" plain>按钮啊</el-button>
      <el-button type="warning" plain>按钮啊</el-button>
      <el-button type="info" plain>按钮啊</el-button>
      <el-button type="default" plain>按钮啊</el-button>
      <br/>

      <el-button type="primary" disabled>按钮啊</el-button>
      <el-button type="danger" disabled>按钮啊</el-button>
      <el-button type="warning" disabled>按钮啊</el-button>
      <el-button type="info" disabled>按钮啊</el-button>
      <el-button type="default" disabled>按钮啊</el-button>
      <br/>

      <el-button type="primary" plain disabled>按钮啊</el-button>
      <el-button type="danger" plain disabled>按钮啊</el-button>
      <el-button type="warning" plain disabled>按钮啊</el-button>
      <el-button type="info" plain disabled>按钮啊</el-button>
      <el-button type="default" plain disabled>按钮啊</el-button>
      <br/>
      <el-select v-model="form.number">
        <el-option :value="1">1</el-option>
      </el-select>
      <el-select v-model="form.array" multiple="">
        <el-option :value="1">1</el-option>
      </el-select>
      <el-radio-group v-model="form.array">
        <el-radio :label="1">1</el-radio>
      </el-radio-group>
      <el-upload v-model="form.obj" />
    </el-form> -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        string: '单方事故',
        array: [],
        number: 1,
        obj: null,
      },
    };
  },
};
</script>

<style scoped>
#app {
  height: 100vh;
  overflow: hidden;
  background: rgb(42, 40, 40);
}
</style>
