import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from '../layout/NavLayout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/404',
    name: 'notFound',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/systemManage',
    name: 'systemManage',
    redirect: '/systemManage/deviceManage',
    component: Layout,
    children: [
      {
        path: 'deviceManage',
        name: 'deviceManage',
        meta: {
          name: '设备管理',
          icon: 'icon-side-camera.png',
        },
        component: () => import('../views/deviceManage/Index.vue'),
      },
      {
        path: 'safetySetting',
        name: 'safetySetting',
        meta: {
          name: '安全策略',
          icon: 'icon-side-pyramid.png',
        },
        component: () => import('../views/safetySetting/Index.vue'),
      },
      // {
      //   path: 'safetySetting',
      //   name: 'safetySetting',
      //   meta: {
      //     name: '安全策略',
      //     icon: 'icon-side-pyramid.png',
      //   },
      //   component: () => import('../views/safetySetting/Index.vue'),
      //   children: [
      //     {
      //       meta: {
      //         name: '人员检测',
      //         icon: 'icon-side-shield.png',
      //         redirectName: 'safetySetting',
      //       },
      //       path: 'personnel',
      //       name: 'safetySettingPeople',
      //       component: () => import('../views/safetySetting/People.vue'),
      //     },
      //     {
      //       meta: {
      //         name: '设备检测',
      //         icon: 'icon-side-shield.png',
      //         redirectName: 'safetySetting',
      //       },
      //       path: 'equipment',
      //       name: 'safetySettingDevice',
      //       component: () => import('../views/safetySetting/Devices.vue'),
      //     },
      //     {
      //       meta: {
      //         name: '物料检测',
      //         icon: 'icon-side-shield.png',
      //         redirectName: 'safetySetting',
      //       },
      //       path: 'material',
      //       name: 'safetySettingMaterial',
      //       component: () => import('../views/safetySetting/Material.vue'),
      //     },
      //     {
      //       meta: {
      //         name: '环境检测',
      //         icon: 'icon-side-shield.png',
      //         redirectName: 'safetySetting',
      //       },
      //       path: 'environment',
      //       name: 'safetySettingEnvironment',
      //       component: () => import('../views/safetySetting/Environment.vue'),
      //     },
      //     {
      //       meta: {
      //         name: '工艺检测',
      //         icon: 'icon-side-shield.png',
      //         redirectName: 'safetySetting',
      //       },
      //       path: 'technology',
      //       name: 'safetySettingTechnology',
      //       component: () => import('../views/safetySetting/Technology.vue'),
      //     },
      //   ],
      // },
      {
        path: 'alertPost',
        name: 'alertPost',
        meta: {
          name: '告警推送',
          icon: 'icon-side-shield.png',
        },
        component: () => import('../views/alertPost/Index.vue'),
      },
      {
        path: 'userFace',
        name: 'userFace',
        meta: {
          name: '人脸布控',
          icon: 'icon-side-snap.png',
        },
        component: () => import('../views/userFace/Index.vue'),
      },
      {
        path: 'peoplePatrol',
        name: 'peoplePatrol',
        meta: {
          name: '人员巡检',
          icon: 'icon-side-patrol.png',
        },
        component: () => import('../views/peoplePatrol/Index.vue'),
      },
      {
        path: 'storageManage',
        name: 'storageManage',
        meta: {
          name: '存储策略',
          icon: 'icon-side-machine.png',
        },
        component: () => import('../views/storageManage/Index.vue'),
      },
      {
        path: 'userManage',
        name: 'userManage',
        meta: {
          name: '用户权限',
          icon: 'icon-side-permission.png',
        },
        component: () => import('../views/userManage/Index.vue'),
      },
      {
        path: 'userDetail',
        name: 'userDetail',
        meta: {
          name: '用户详情',
          icon: 'icon-side-users.png',
          redirectName: 'userManage',
        },
        component: () => import('../views/userManage/components/UserDetail.vue'),
      },
      {
        path: 'roleDetail',
        name: 'roleDetail',
        meta: {
          name: '用户详情',
          icon: 'icon-side-users.png',
          redirectName: 'userManage',
        },
        component: () => import('../views/userManage/components/RoleDetail.vue'),
      },
      {
        path: 'logsManage',
        name: 'logsManage',
        meta: {
          name: '运维管理',
          icon: 'icon-side-logs.png',
        },
        component: () => import('../views/logsManage/Index.vue'),
      },
      {
        path: 'systemInfo',
        name: 'systemInfo',
        meta: {
          name: '系统信息',
          icon: 'icon-side-file.png',
        },
        component: () => import('../views/systemInfo/Index.vue'),
      },
      {
        path: 'preAuditAlert',
        name: 'preAuditAlert',
        meta: {
          name: '算法审核',
          icon: 'icon-side-logs.png',
        },
        component: () => import('../views/alertPreAudit/alertData/components/Index.vue'),
      },
      {
        path: 'preAuditAlertDetail',
        name: 'preAuditAlertDetail',
        meta: {
          name: '算法审核告警',
          icon: 'icon-side-logs.png',
          redirectName: 'preAuditAlert',
        },
        component: () => import('../views/alertPreAudit/alertData/components/Detail.vue'),
      },
    ],
  },
  {
    path: '/dataCenter',
    name: 'dataCenter',
    redirect: '/dataCenter/dashboard',
    component: Layout,
    children: [
      {
        meta: {
          hideSide: true,
        },
        path: 'dashboard',
        name: 'dataCenterIndex',
        component: () => import('../views/dataCenter/Index.vue'),
      },
    ],
  },
  {
    path: '/historyQuery',
    name: 'historyQuery',
    redirect: '/historyQuery/alertData',
    component: Layout,
    children: [
      {
        path: 'alertData',
        name: 'alertData',
        meta: {
          name: '系统告警',
          icon: 'icon-side-logs.png',
        },
        component: () => import('../views/historyQuery/alertData/components/Index.vue'),
      },
      {
        path: 'alertDetail',
        name: 'alertDetail',
        meta: {
          name: '系统告警',
          icon: 'icon-side-logs.png',
          redirectName: 'alertData',
        },
        component: () => import('../views/historyQuery/alertData/components/Detail.vue'),
      },
      {
        path: 'patrolData',
        name: 'patrolData',
        meta: {
          name: '巡检告警',
          icon: 'icon-side-patrol.png',
        },
        component: () => import('../views/historyQuery/patrolData/Index.vue'),
      },
      {
        path: 'chartsData',
        name: 'chartsData',
        meta: {
          name: '数据报表',
          icon: 'icon-side-charts.png',
        },
        component: () => import('../views/historyQuery/chartsData/Index.vue'),
      },
      {
        path: 'dashboardData',
        name: 'dashboardData',
        meta: {
          name: '仪表仪盘',
          icon: 'icon-side-machine.png',
        },
        component: () => import('../views/historyQuery/dashboardData/Index.vue'),
      },
    ],
  },
  {
    path: '/',
    redirect: '/dataCenter',
  },
  {
    path: '/auditManage',
    name: 'auditManage',
    redirect: '/auditManage/list',
    component: Layout,
    children: [
      {
        meta: {
          hideSide: true,
        },
        path: 'list',
        name: 'auditManageIndex',
        component: () => import('../views/auditManage/Index.vue'),
      },
    ],
  },
];

const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login' && !sessionStorage.getItem('token')) {
    next({ name: 'login' });
  }
  console.log(to);
  next();
});
export default router;
