import service from '../../utils/request';

export default {
  // 区域树查询
  fetchOrgTree(params) {
    return service.get('/api/user/department/find', { params });
  },
  // 区域树查询(只查询非叶子节点)
  fetchOrgParentTree(params) {
    return service.get('/api/user/department/find/tree', { params });
  },
  createOrg(data) {
    return service.post('/api/user/department/save', data);
  },
  updateOrg(data) {
    return service.post('/api/user/department/update', data);
  },
  deleteOrg(data) {
    return service.post('/api/user/department/remove', data);
  },
  // 查询用户列表
  fetchUserList(params) {
    return service.get('/api/user/find', { params });
  },
  // 查询用户详情
  fetchUserDetail(params) {
    return service.get('/api/user/detail/find', { params });
  },
  // 模糊搜索用户
  fetchUserListByKeywords(params) {
    return service.get('/api/push/push_user', { params });
  },
  createUser(data) {
    return service.post('/api/user/save', data);
  },
  updateUser(data) {
    return service.post('/api/user/update', data);
  },
  deleteUser(data) {
    return service.post('/api/user/remove', data);
  },
  batchMoveUser(data) {
    return service.post('/api/user/department/move', data);
  },
  // 修改用户状态 停用/启用
  setUserStatus(params) {
    return service.get('/api/user/update/status', { params });
  },
  // 查询系统内所有角色
  fetchRoleList(params) {
    return service.get('/api/user/role/find/fixed-ids', { params });
  },
  // 查询角色分页列表
  fetchRoleData(params) {
    return service.get('/api/user/role/list', { params });
  },
  // 查询角色详情
  fetchRoleDetail(params) {
    return service.get('/api/user/role/find/details', { params });
  },
  // 查询角色详情
  fetchRoleDetailByType(params) {
    return service.get('/api/user/role/find_by_type', { params });
  },
  // 新增角色
  createRole(data) {
    return service.post('/api/user/role/save', data);
  },
  // 编辑角色
  updateRole(data) {
    return service.post('/api/user/role/update', data);
  },
  // 删除角色
  deleteRole(data) {
    return service.post('/api/user/role/remove', data);
  },
  // 查询具体用户的角色(id, 为自定义时才需要查询)对应的权限
  fetchAuthorityDetail(params) {
    return service.get('/api/user/role/find', { params });
  },
  importOrg(data) {
    return service.post('/api/user/department/upload', data);
  },
  downloadOrgError(data) {
    return service.post('/api/user/department/download_error', data, { responseType: 'blob' });
  },
  // // 查询日志数据
  // fetchLogsDataTree(params) {
  //   return service.get('/api/storage/log/find', { params });
  // },
  // // 查询历史数据
  // fetchAlertList(params) {
  //   return service.get('/api/alarm/history', { params });
  // },
  // fetchAlertDetail(params) {
  //   return service.get('/api/alarm/info', { params });
  // },
  // // 获取已部署的算法类型
  // fetchDeployTypeList(params) {
  //   return service.get('/api/core/warning/deploy_list', { params });
  // },
  // // 导出 历史数据
  // exportAlertData(data) {
  //   return service.post('/api/alarm/download', data, { responseType: 'blob' });
  // },
  // // 导出 日志数据
  // exportLogsData(data) {
  //   return service.post('/api/storage/log/download', data, { responseType: 'blob' });
  // },
  fetchRoleTree(params) {
    return service.get('api/user/role/tree', { params });
  },
  // 创建随机强校验密码
  createPassword(data) {
    return service.post('/api/user/password/generate', data);
  },
};
