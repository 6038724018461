import { decode } from 'js-base64';
import router from '@/router';
import resetMessage from './resetMessage';

// eslint-disable-next-line
Date.prototype.Format = function (fmt) {
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    // eslint-disable-next-line
    fmt = fmt.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  // eslint-disable-next-line
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      // eslint-disable-next-line
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  }
  return fmt;
};

export default {
  dateFormat(date = null, formatStr = 'yyyy-MM-dd') {
    return new Date(date).Format(formatStr);
  },
  // 校验权限是否存在
  validPermission(authStr) {
    let auth = true;
    try {
      auth = JSON.parse(decode(sessionStorage.getItem('permissions')));
    } catch (error) {
      resetMessage.error('获取权限信息失败, 请重新登录!');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('permissions');
      sessionStorage.removeItem('warnings');
      sessionStorage.removeItem('username');
      router.push({ path: '/login' });
    }
    return auth.indexOf(authStr) < 0;
  },
  unique(arr, val) {
    const res = new Map();
    return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
  },
  arrSubtraction(a, b) {
    if (Array.isArray(a) && Array.isArray(b)) {
      return a.filter((i) => !b.includes(i));
    }
    throw new new TypeError('arguments must be array')();
  },
};
