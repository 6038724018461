import service from '../../utils/request';

export default {
  fetchDataCenterConfig(params) {
    return service.get('/api/statistic/find_config', { params });
  },
  updateDataCenterConfig(data) {
    return service.post('/api/statistic/update_config', data);
  },
  fetchDeviceStatusCharts(params) {
    return service.get('/api/statistic/camera_register_status', { params });
  },
  fetchDataCenterAlertList(params) {
    return service.get('/api/statistic/alarm_data', { params });
  },
  fetchAlertLevelCharts(params) {
    return service.get('/api/statistic/alarm_level_distribution', { params });
  },
  fetchAlertTrendCharts(params) {
    return service.get('/api/statistic/alarm_data_trend', { params });
  },
  fetchAlertRank(params) {
    return service.get('/api/statistic/alarm_frequently_location', { params });
  },
  fetchAlertPieCharts(params) {
    return service.get('/api/statistic/alarm_type_proportion', { params });
  },
  fetchCarCount(params) {
    return service.get('/api/statistic/vehicle_unloading_statistic', { params });
  },
  fetchAlertBarCharts(params) {
    return service.get('/api/statistic/alarm_statistic_situation', { params });
  },
  fetchAlertCount(params) {
    return service.get('/api/statistic/alarm_event_statistic', { params });
  },
  fetchAlertCountFull(params) {
    return service.get('/api/statistic/alarm_quantity_statistic', { params });
  },
  fetchInsectRank(params) {
    return service.get('/api/statistic/insect/statistic', { params });
  },
  importInsect(data) {
    return service.post('/api/core/insect/upload', data);
  },
  // 下载虫情错误数据
  downloadInsectError(data) {
    return service.post('/api/core/insect/download_error', data, { responseType: 'blob' });
  },
  // updateThirdConfig(data) {
  //   return service.post('/api/push/third-system/save', data);
  // },
  fetchCameraAliveList(params) {
    return service.get('/api/statistic/camera_live', { params });
  },
  // 获取卸货统计
  fetchOffCount(params) {
    return service.get('/api/statistic/sampling_detection_statistic', { params });
  },
  fetchMapCameraList(params) {
    return service.get('/api/statistic/camera_data', { params });
  },
  // 传感器+摄像头
  fetchMapDeviceList(params) {
    return service.get('/api/statistic/map_data', { params });
  },
  fetchUnhandleDataByCameraId(params) {
    return service.get('/api/alarm/process/camera_unprocessed', { params });
  },
  fetchUnhandleDataBySensorId(params) {
    return service.get('/api/alarm/process/sensor_unprocessed', { params });
  },
};
