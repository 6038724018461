import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: '安全生产项目', // 项目名称
    socketData: {}, // socket推送的正确JSON Object数据, 仅当正确解析时才会更新
    auth: 'success', // 授权状态: init 授权中, success 已授权, 未授权 no_auth, 已过期 expired
    warning: {
      environment: [],
      equipment: [],
      material: [],
      personnel: [],
    },
    deployType: 1, // 部署模式 1 中心部署 2 混合部署
    showMap: 'map', // map 显示地图 camera 显示流媒体,
    alertDataForm: null, // 告警数据的查询表单数据,
    cameraDataForm: null, // 告警数据的查询表单数据,
    playVoice: false,
  },
  getters: {},
  mutations: {
    setAuth(state, data) {
      state.auth = data;
    },
    setWarning(state, data) {
      state.warning = data;
    },
    setDeployType(state, data) {
      state.deployType = data;
    },
    setMessageData(state, data) {
      state.socketData = data;
    },
    switchShowMap(state, data) {
      state.showMap = data;
    },
    setKeyValue(state, { key, value }) {
      state[key] = value;
    },
  },
  actions: {},
  modules: {},
});
