import service from '../../utils/request';

export default {
  // 查询日志数据
  fetchLogsDataTree(params) {
    return service.get('/api/storage/log/find', { params });
  },
  // 获取已部署的算法类型
  fetchDeployTypeList(params) {
    return service.get('/api/core/warning/deploy_list', { params });
  },
  // 导出 历史数据
  exportAlertData(data) {
    return service.post('/api/alarm/download', data, { responseType: 'blob' });
  },
  // 导出 日志数据
  exportLogsData(data) {
    return service.post('/api/storage/log/download', data, { responseType: 'blob' });
  },
  // 合并告警处理
  moveoutMergeAlert(data) {
    return service.post('/api/alarm/merge_remove', data);
  },
  deleteMergeAlert(data) {
    return service.post('/api/alarm/merge_delete', data);
  },
  // 查询日志数据
  fetchPLCList(params) {
    return service.get('/api/camera/plc/list', { params });
  },
  updatePLC(data) {
    return service.post('/api/camera/plc/update', data);
  },
  // 安装包数据
  fetchPackageList(params) {
    return service.get('/api/core/install_package/list_with_page', { params });
  },
  uploadPackage(data) {
    return service.post('/api/storage/install/package/upload', data, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  },
  createPackage(data) {
    return service.post('/api/core/install_package/create', data);
  },
  applyPackage(data) {
    return service.post('/api/core/install_package/apply', data);
  },
  deletePackage(data) {
    return service.post('/api/core/install_package/delete', data);
  },
};
