import service from '../../utils/request';

export default {
  fetchPreAuditAlertList(params) {
    return service.get('/api/alarm/conceal/list', { params });
  },
  fetchPreAuditAlertDetail(params) {
    return service.get('/api/alarm/conceal/info', { params });
  },
  deletePreAuditAlert(data) {
    return service.post('/api/alarm/conceal/delete', data);
  },
  postPreAuditAlert(data) {
    return service.post('/api/alarm/conceal/push', data);
  },
  exportPreAuditAlert(data) {
    return service.post('/api/alarm/conceal/download', data, { responseType: 'blob' });
  },
};
