// 通用模块
import commonAPI from './modules/commom';
// 数据中心
import dataCenterAPI from './modules/dataCenter';
// 区域管理
import deviceAPI from './modules/device';
// 安全策略(算法配置)管理
import safeSettingAPI from './modules/safeSettings';
// 告警推送
import alertPostAPI from './modules/alertPost';
// 存储策略
import storageAPI from './modules/storage';
// 报警/日志管理
import logsAPI from './modules/alerts&logs';
// 系统信息
import systemAPI from './modules/system';
// 用户管理(权限)
import authorityAPI from './modules/user&authority';
// 历史数据
import historyQueryAPI from './modules/historyQuery';
// 审核中心
import auditAPI from './modules/audit';
// 人员巡检
import patrolAPI from './modules/patrol';
// 人脸布控
import userFaceAPI from './modules/userFace';
// 算法审核
import preAuditAlertAPI from './modules/preAuditAlert';

export default {
  ...commonAPI,
  ...dataCenterAPI,
  ...deviceAPI,
  ...safeSettingAPI,
  ...alertPostAPI,
  ...storageAPI,
  ...logsAPI,
  ...systemAPI,
  ...authorityAPI,
  ...historyQueryAPI,
  ...auditAPI,
  ...patrolAPI,
  ...userFaceAPI,
  ...preAuditAlertAPI,
};
