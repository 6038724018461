import service from '../../utils/request';

export default {
  // 查询算法策略
  fetchSafeSetting(params) {
    return service.get('/api/core/strategy/list', { params });
  },
  updateSetting(data) {
    return service.post('/api/core/update_strategy', data);
  },
  createSetting(data) {
    return service.post('/api/core/create_strategy', data);
  },
  deleteSetting(data) {
    return service.post('/api/core/delete_strategy', data);
  },
  // 应用于全部摄像机
  setToAllCamera(data) {
    return service.post('/api/core/apply_strategy_all', data);
  },
  // 应用于部分摄像机
  setToPartCamera(data) {
    return service.post('/api/core/apply_strategy', data);
  },
  // 获取可以配置的摄像机
  fetchCameraFromStrategy(params) {
    return service.get('/api/core/camera/list_by_strategy', { params });
  },
  // 获取已被配置的摄像机(鉴权)
  fetchCameraFromStrategyChecked(params) {
    return service.get('/api/core/camera/list_by_sid-auth', { params });
  },
  // 获取可以配置的传感器
  fetchSensorFromStrategy(params) {
    return service.get('/api/core/sensor/list_by_strategy', { params });
  },
  // 获取已被配置的传感器(鉴权)
  fetchSensorFromStrategyChecked(params) {
    return service.get('/api/core/sensor/list_by_sid-auth', { params });
  },
  resetSafeSetting(data) {
    return service.post('/api/core/restore_strategy', data);
  },
  fetchCommonConfig(params) {
    return service.get('/api/core/strategy/find_common_config', { params });
  },
  updateCommonConfig(data) {
    return service.post('/api/core/strategy/update_common_config', data);
  },
};
