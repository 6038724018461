import service from '../../utils/request';

export default {
  // 区域树查询
  fetchAreaTree(params) {
    return service.get('/api/core/area/find', { params });
  },
  // 区域树查询(只查询非叶子节点)
  fetchAreaParentTree(params) {
    return service.get('/api/core/area/find/tree', { params });
  },
  createArea(data) {
    return service.post('/api/core/area/save', data);
  },
  updateArea(data) {
    return service.post('/api/core/area/update', data);
  },
  deleteArea(data) {
    return service.post('/api/core/area/remove', data);
  },
  fetchCameraList(params) {
    return service.get('/api/core/camera/list', { params });
  },
  createCamera(data) {
    return service.post('/api/core/camera/create', data);
  },
  updateCamera(data) {
    return service.post('/api/core/camera/modify', data);
  },
  deleteCamera(data) {
    return service.post('/api/core/camera/delete', data);
  },
  fetchCameraDetail(params) {
    return service.get('/api/core/camera/info', { params });
  },
  fetchCameraStream(params) {
    return service.get('/api/camera/account', { params });
  },
  refreshCamera(params) {
    return service.get('/api/camera/refresh/cover', { params });
  },
  bindAbility(data) {
    return service.post('/api/core/camera/binding_ability', data);
  },
  batchMoveCamera(data) {
    return service.post('/api/core/camera/batch_move', data);
  },
  importCamera(data) {
    return service.post('/api/core/camera/upload', data);
  },
  fetchAnnuciatorsList(params) {
    return service.get('/api/annuciator/list', { params });
  },
  // 下载模板
  downloadTemplate() {
    return service.get('/api/core/camera/upload_template', null, { responseType: 'blob' });
  },
  // 下载错误数据
  downloadError(data) {
    return service.post('/api/core/camera/download_error', data, { responseType: 'blob' });
  },
  // 编辑摄像机基本信息
  updateCameraBasic(data) {
    return service.post('/api/core/camera/base/modify', data);
  },
  // 编辑摄像机外部设备
  updateCameraDevice(data) {
    return service.post('/api/core/camera/equipment/modify', data);
  },
  // 导出摄像机数据
  exportCamera(data) {
    return service.post('/api/core/camera/download', data, { responseType: 'blob' });
  },
  fetchClientList(params) {
    return service.get('/api/core/edge/list', { params });
  },
  fetchClientListByArea(params) {
    return service.get('/api/core/edge/list-area', { params });
  },
  updateClient(data) {
    return service.post('/api/core/edge/update', data);
  },
  deleteClient(data) {
    return service.post('/api/core/edge/remove', data);
  },
  fetchClientCameraList(params) {
    return service.get('/api/core/camera/list_by_eid', { params });
  },

  fetchAnnuciatorPageList(params) {
    return service.get('/api/annuciator/list_with_page', { params });
  },
  updateAnnuciator(data) {
    return service.post('/api/annuciator/update_nickname', data);
  },
  testAnnuciator(params) {
    return service.get('/api/annuciator/test', { params });
  },
  // 传感器
  fetchSensorList(params) {
    return service.get('/api/core/sensor/list_with_page', { params });
  },
  fetchSensorDetail(params) {
    return service.get('/api/core/sensor/info', { params });
  },
  updateSensor(data) {
    return service.post('/api/core/sensor/base/modify', data);
  },
  exportSensor(data) {
    return service.post('/api/core/sensor/download', data, { responseType: 'blob' });
  },
  batchMoveSensor(data) {
    return service.post('/api/core/sensor/batch_move', data);
  },
  uploadSensorCover(data) {
    return service.post('/api/storage/upload_file_sensor', data, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  },
  bindSensorAbility(data) {
    return service.post('/api/core/sensor/binding_ability', data);
  },
  updateSensorDevice(data) {
    return service.post('/api/core/sensor/equipment/modify', data);
  },
};
