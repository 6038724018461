import service from '../../utils/request';

export default {
  // 查询系统信息
  fetchSystemConfig(params) {
    return service.get('/api/core/v1/deploy/findInfo', { params });
  },
  // 查询系统信息(触发鉴权)
  fetchSystemConfigAuth(params) {
    return service.get('/api/core/v1/deploy/findInfo-auth', { params });
  },
  // 更新授权
  updateLicense(data) {
    return service.post('/api/core/v1/deploy/upload_license_file', data);
  },
  // 切换系统部署方式
  switchDeployType(params) {
    return service.get('/api/core/v1/deploy/update_type', { params });
  },
  // 获取机器码
  getMachineCode(params) {
    return service.get('/api/core/v1/offLicense/getMachineCode', { params });
  },
  // 下载机器码的TXT文件
  downloadMachineCodeTXT(params) {
    return service.get('/api/core/v1/offLicense/download', { params, responseType: 'blob' });
  },
  // 更新授权
  updateOffLineLicense(data) {
    return service.post('/api/core/v1/offLicense/active', data);
  },
  // 获取运维监控
  fetchDashboard(params) {
    return service.get('/api/core/config/grafana_page_link', { params });
  },
};
