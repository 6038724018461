import service from '../../utils/request';

export default {
  fetchNotHandleList(params) {
    return service.get('/api/alarm/process/list_unprocessed', { params });
  },
  fetchHandledList(params) {
    return service.get('/api/alarm/process/list_processed', { params });
  },
  fetchNotAuditList(params) {
    return service.get('/api/alarm/process/list_unapproved', { params });
  },
  fetchAuditList(params) {
    return service.get('/api/alarm/process/list_approved', { params });
  },
  // 获取审核中心告警事件详情
  fetchAuditDetail(params) {
    return service.get('/api/alarm/process/info', { params });
  },
  singleHandleAudit(data) {
    return service.post('/api/alarm/process/save', data);
  },
  multiHandleAudit(data) {
    return service.post('/api/alarm/process/saveBatch', data);
  },
};
