<template>
  <div
    class="layout-wrapper"
    :class="{ 'show-wild-map': currentRouteName === 'dataCenterIndex' && returnShowMap }"
    v-loading="loading"
    element-loading-background="rgba(0,0,0,.5)"
    element-loading-text="系统正在授权中..."
  >
    <!-- <iframe
      src="assets/img/emos/silence.mp3"
      type="audio/mp3"
      allow="autoplay"
      id="audio"
      style="display: none"
    ></iframe> -->
    <header class="header animate__animated animate__fadeInDown animate__faster">
      <!-- <div class="logo"></div> -->
      <h1 class="logo-text" style="margin-left: 20px">安全生产AI管控平台</h1>
      <ul class="navs">
        <li
          v-if="checkHeaderNavVisible('data-center')"
          class="nav-item data"
          :class="{
            'active animate__animated animate__zoomIn animate__faster': activeName === 'dataCenter',
          }"
          @click="handleRouteClicked('dataCenter', 'data-center')"
        />
        <li
          v-if="checkHeaderNavVisible('history')"
          class="nav-item history"
          :class="{
            'active animate__animated animate__zoomIn animate__faster':
              activeName === 'historyQuery',
          }"
          @click="handleRouteClicked('historyQuery', 'history')"
        />
        <li
          v-if="checkHeaderNavVisible('audit-center')"
          class="nav-item audit"
          :class="{
            'active animate__animated animate__zoomIn animate__faster':
              activeName === 'auditManage',
          }"
          @click="handleRouteClicked('auditManage', 'audit')"
        />
        <li
          v-if="checkHeaderNavVisible('system-config')"
          class="nav-item manage"
          :class="{
            'active animate__animated animate__zoomIn animate__faster':
              activeName === 'systemManage',
          }"
          @click="handleRouteClicked('systemManage', 'system-config')"
        />
      </ul>
      <div class="right-area">
        <div class="right-item">
          <div class="title">日期</div>
          <div class="highlight-text">
            <img src="../assets/img/nav&side/icon-calendar.png" alt="" />
            {{ currentDate }}
          </div>
        </div>
        <div class="right-item">
          <div class="title">时间</div>
          <div class="highlight-text">
            <img src="../assets/img/nav&side/icon-clock.png" alt="" />
            {{ currentTime }}
          </div>
        </div>
        <div
          v-if="currentRouteName === 'dataCenterIndex'"
          class="right-item switch-icon animate__animated animate__fadeInDown animate__faster"
          @click.capture="switchShowMap"
        >
          <img v-if="!returnShowMap" src="@/assets/img/nav&side/icon-map.png" alt="" />
          <img v-else src="@/assets/img/nav&side/icon-camera.png" alt="" />
        </div>
        <div class="right-item user">
          <el-dropdown @command="handleDropdownClick">
            <div class="user-dropdown-hover">
              <img src="../assets/img/nav&side/default-user-avatar.png" alt="" />
              <span class="user-dropdown-text"> {{ username }} </span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>{{ username }}</el-dropdown-item>
              <el-dropdown-item command="voice" divided
                >{{ this.$store.state.playVoice ? '关闭' : '打开' }}声音</el-dropdown-item
              >
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div
        v-if="authStatus !== 'success'"
        class="no-auth animate__animated animate__flash animate__slower animate__infinite"
      >
        {{
          authStatus === 'expired'
            ? '授权已失效'
            : authStatus === 'init'
            ? '正在授权中...'
            : '系统未授权'
        }}
      </div>
    </header>
    <div class="content">
      <div v-if="showSide" class="sidebar animate__animated animate__fadeInLeft animate__faster">
        <el-menu
          background-color="transparent"
          text-color="#ffffff"
          active-text-color="#ffffff"
          :default-active="activeMenu"
          @select="handleNavSelected"
        >
          <template v-for="item in menus">
            <el-submenu
              v-if="item.children && item.children.length > 0"
              :key="`${item.name}submenu`"
              :index="item.name"
            >
              <template slot="title" class="menu-item">
                <img
                  class="nav-icon"
                  :src="require(`../assets/img/nav&side/${item.meta.icon}`)"
                  alt=""
                />
                <span class="nav-text">{{ item.meta.name }}</span>
              </template>
              <el-menu-item v-for="sub in item.children" :key="`${sub.name}menu`" :index="sub.name">
                <span class="nav-text">{{ sub.meta.name }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else class="menu-item" :key="`${item.name}menu`" :index="item.name">
              <img
                class="nav-icon"
                :src="require(`../assets/img/nav&side/${item.meta.icon}`)"
                alt=""
              />
              <span class="nav-text">{{ item.meta.name }}</span>
            </el-menu-item>
          </template>
          <el-menu-item
            v-if="username === 'auditor' && activeName === 'systemManage'"
            class="menu-item"
            index="preAuditAlert"
          >
            <img
              class="nav-icon"
              :src="require('../assets/img/nav&side/icon-side-logs.png')"
              alt=""
            />
            <span class="nav-text">算法审核</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="router-view-container" :class="{ full: !showSide }">
        <div class="router-container">
          <router-view class="animate__animated animate__fadeIn animate__faster" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReconnectingWebSocket from 'reconnecting-websocket';
import dayjs from 'dayjs';
import { decode } from 'js-base64';

export default {
  computed: {
    returnShowMap() {
      return this.$store.state.showMap === 'map';
    },
  },
  watch: {
    $route: {
      handler(newV) {
        if (newV) {
          this.currentRouteName = newV.name;
        }
        this.showSide = !newV.meta.hideSide;
        try {
          this.permissions = JSON.parse(decode(sessionStorage.getItem('permissions')));
        } catch (error) {
          this.$message.error('权限信息错误, 请重新登陆!');
        }
        this.$router.options.routes.forEach((item) => {
          if (item.name === this.$route.matched[0].name) {
            // 处理一级路由
            this.menus = item.children.filter((router) => {
              const includes = this.permissions.includes(this.routerToPermission[router.name]);
              // // 特殊处理安全策略路由
              // if (router.name === 'safetySetting') {
              //   // eslint-disable-next-line
              //   const result = router.children.filter((child) => {
              //     // eslint-disable-next-line
              //     return this.warnings[child.path] && this.warnings[child.path].length > 0;
              //   });
              //   // 直接重写children
              //   // eslint-disable-next-line
              //   router.children = result;
              // }
              return includes;
            });
          }
        });
        if (this.$route.matched.length > 1) {
          this.activeName = this.$route.matched[0].name;
        }
        // 根据当前路由匹配层级来恢复导航选中
        if (this.$route.matched.length > 2) {
          this.activeMenu = this.$route.matched[2].name;
        } else if (this.$route.matched.length > 1) {
          this.activeMenu = this.$route.matched[1].name;
        }
        // 有direct则直接恢复选中
        if (newV.meta.redirectName) {
          this.activeMenu = newV.meta.redirectName;
        }
      },
      deep: true,
      immediate: true,
    },
    '$store.state.auth': {
      handler(newV, oldV) {
        this.authStatus = newV;
        if (newV === 'init') {
          this.loading = true;
        } else {
          this.loading = false;
          if (oldV !== 'success') {
            this.$message.success('授权已完成, 请重新登录!');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('permissions');
            sessionStorage.removeItem('warnings');
            sessionStorage.removeItem('username');
            // 上述内容丢失会自动退登
            setTimeout(() => {
              window.location.reload(true);
            }, 500);
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      systemInfo: {},
      showSide: false,
      currentDate: '',
      currentTime: '',
      activeName: '',
      activeMenu: '',
      menus: [],
      clockInterval: null,
      websocket: null,
      authStatus: '',
      username: '',
      permissions: [],
      routerToPermission: {
        deviceManage: 'system-config/device/FIND',
        safetySetting: 'system-config/safe/FIND',
        alertPost: 'system-config/push/FIND',
        peoplePatrol: 'system-config/inspection-info/FIND',
        userManage: 'system-config/user/FIND',
        storageManage: 'system-config/disk/FIND',
        logsManage: 'system-config/log/FIND',
        systemInfo: 'system-config/system/FIND',
        dashboardData: 'history/instrument/FIND',
        alertData: 'history/alarm-data/FIND',
        chartsData: 'history/report/FIND',
        patrolData: 'history/inspection-data/FIND',
        userFace: 'system-config/person/FIND',
      },
      currentRouteName: '',
    };
  },
  beforeCreate() {
    try {
      this.warnings = JSON.parse(decode(sessionStorage.getItem('warnings')));
      this.permissions = JSON.parse(decode(sessionStorage.getItem('permissions')));
      console.log(this.permissions);
    } catch (error) {
      this.$message.error('缓存信息读取错误, 请重新登陆!');
    }
  },
  created() {
    this.username = sessionStorage.getItem('username');
    this.getConfigData();
    this.showSide = !this.$route.meta.hideSide;

    this.currentDate = dayjs(new Date()).format('YYYY.MM.DD');
    this.currentTime = dayjs(new Date()).format('HH:mm:ss');
    this.clockInterval = setInterval(() => {
      this.currentTime = dayjs(new Date()).format('HH:mm:ss');
    }, 1000);
    // console.log(this.$route.name);
  },
  mounted() {
    if (this.$route.name === 'dataCenterIndex') {
      this.$confirm('系统将自动播放语告警语音, 是否允许?', '温馨提示')
        .then(() => {
          this.$store.commit('setKeyValue', { key: 'playVoice', value: true });
          this.$message.success('告警语音已开启');
        })
        .catch(() => {
          this.$message.info('您可以在右上角再次打开声音');
        });
      setTimeout(() => {
        this.$msgbox.close();
      }, 5000);
    }
    // 是否有本地缓存
    if (!localStorage.getItem('showMap')) {
      this.$store.commit('switchShowMap', 'map');
      localStorage.setItem('showMap', 'map');
    } else {
      this.$store.commit('switchShowMap', localStorage.getItem('showMap'));
    }
    this.authStatus = this.$store.state.auth;
    this.createWebsocket();
  },
  beforeDestroy() {
    this.websocket.close();
  },
  methods: {
    // 建立websocket，获取推送
    createWebsocket() {
      if ('WebSocket' in window) {
        // const wsuri = `ws://10.10.1.122:18081/websocket/v2/message`;
        // console.log(`ws://${window.location.hostname}:${window.location.port}/api/v2/websocket/message/${localStorage.getItem('userId')}`)
        let wsuri = '';

        const username = sessionStorage.getItem('username');
        if (window.location.protocol === 'http:') {
          wsuri = `ws://${window.location.host}/ws/websocket/v2/message/${+new Date()}/${username}`;
        } else if (window.location.protocol === 'https:') {
          wsuri = `wss://${
            window.location.host
          }/ws/websocket/v2/message/${+new Date()}/${username}`;
        }

        this.websocket = new ReconnectingWebSocket(wsuri, [], {
          // custom WebSocket constructor
          connectionTimeout: 10000,
          maxRetries: 50,
        });
        this.initWebSocket();
      } else {
        this.$message.error('This browser does not support websocket');
      }
    },
    // websocket
    initWebSocket() {
      // 连接错误
      this.websocket.onerror = () => {
        this.websocket.close();
      };

      // 连接成功
      this.websocket.onopen = () => {};

      // // 收到消息的回调
      this.websocket.onmessage = this.handleOnMessage;

      // 连接关闭的回调
      this.websocket.onclose = () => {
        this.websocket.close();
      };

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      window.onbeforeunload = () => {
        this.websocket.close();
      };
    },
    // 接收推送数据
    handleOnMessage(event) {
      // this.$store.commit('changeValue', { key: 'serverIn', data: 6})
      // 过滤多余返回的message
      if (
        event.data.indexOf('WebSocket connection succeeded!') === -1 &&
        event.data !== 'Heartbeat'
      ) {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'license') {
            this.$store.commit('setAuth', data.status);
          } else if (data.type === 'alarmData') {
            this.$store.commit('setMessageData', data.alarmData);
          } else if (data.type === 'camera') {
            // 仅在首页时收到此消息才刷新
            if (this.$route.name === 'dataCenterIndex') {
              // window.location.reload(true);
            }
          }
        } catch (error) {
          this.$message.error('推送告警数据格式错误!');
        }
      }
    },
    getConfigData() {
      this.API.fetchSystemConfig().then((res) => {
        this.loading = res.data.auth.status === 'init';
        this.$store.commit('setAuth', res.data.auth.status);
        this.$store.commit('setWarning', res.data.auth.warning);
        this.$store.commit('setDeployType', res.data.deploy.type);
      });
    },
    handleDropdownClick(command) {
      this[command]();
    },
    logout() {
      this.$confirm('确认退出登录?', '提示')
        .then(() => {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('permissions');
          sessionStorage.removeItem('warnings');
          sessionStorage.removeItem('username');
          this.$router.push({ name: 'login' });
        })
        .catch(() => {});
    },
    voice() {
      const { playVoice } = this.$store.state;
      this.$store.commit('setKeyValue', { key: 'playVoice', value: !playVoice });
    },
    checkHeaderNavVisible(permissionName) {
      // 校验权限, 查询是否顶部导航包含有find权限, 若没有则不显示顶部导航
      try {
        this.permissions.forEach((per) => {
          // 组合查询权限
          if (permissionName === 'audit-center') {
            // 由于审核中心权限特殊, 不再使用FIND
            if (
              (per.indexOf('PROCESS') !== -1 || per.indexOf('AUDIT') !== -1) &&
              per.indexOf(permissionName) !== -1
            ) {
              throw per;
            }
            // system-config/xxx/FIND, 仅查询出第一个符合条件的内容, 查询成功时报错退出循环
          } else if (per.indexOf('FIND') !== -1 && per.indexOf(permissionName) !== -1) {
            throw per;
          }
        });
      } catch (error) {
        return true;
      }
      return false;
    },
    handleRouteClicked(routerName, permissionName) {
      this.activeName = routerName;
      // 校验权限后再进入多级子页面
      if (routerName !== 'dataCenter' && routerName !== 'auditManage') {
        // 校验权限, 进入第一个有find(查看)权限的界面, 如果没有 无法进入系统
        try {
          this.permissions.forEach((per) => {
            // 组合查询权限
            // system-config/xxx/FIND, 仅查询出第一个符合条件的内容, 查询成功时报错退出循环
            if (per.indexOf('FIND') !== -1 && per.indexOf(permissionName) !== -1) {
              throw per;
            }
          });
        } catch (error) {
          // 由permission的值(system-config/safe/FIND)还原到router的值(safetySetting), 用于跳转
          Object.keys(this.routerToPermission).forEach((key) => {
            if (error === this.routerToPermission[key]) {
              // 由于已创建的router无法再修改redirect, 所以在源头控制其前往的页面
              if (key === 'safetySetting') {
                // 已授权时 一定会有算法显示
                try {
                  Object.keys(this.warnings).forEach((innerKey) => {
                    if (this.warnings[innerKey].length > 0) {
                      throw innerKey;
                    }
                  });
                } catch (innerError) {
                  // 前往一定存在算法的页面
                  // path: `/systemManage/safetySetting?tabName=${innerError}`
                  this.$router.push({ path: '/systemManage/safetySetting' });
                }
              } else {
                this.$router.push({ name: key });
              }
            }
          });
        }
      } else if (routerName === 'auditManage') {
        console.log(this.permissions.findIndex((item) => item === 'audit-center/PROCESS'));
        // 判断是否含有处理权限即可, 若无处理则进入审核, 均无则此导航将不显示
        if (this.permissions.findIndex((item) => item === 'audit-center/PROCESS') === -1) {
          this.$router.push({ name: 'auditManage', query: { type: 'notAudit' } });
        } else {
          this.$router.push({ name: routerName });
        }
      } else {
        // 首页是单页界面, 有权限时会显示
        this.$router.push({ name: routerName });
      }
    },
    handleNavSelected(nav) {
      this.$router.push({ name: nav });
    },
    switchShowMap() {
      const showMap = localStorage.getItem('showMap');
      this.$store.commit('switchShowMap', showMap === 'camera' ? 'map' : 'camera');
      localStorage.setItem('showMap', showMap === 'camera' ? 'map' : 'camera');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown-hover {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > img {
    margin-top: -10px;
    height: 55px;
    width: 55px;
  }
  .user-dropdown-text {
    width: 100px;
    margin-top: -8px;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    color: #919191;
    text-align: center;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.layout-wrapper {
  margin: 0;
  box-shadow: 0 0 10px inset #000000;
  // background: url('../assets/img/map-bg.jpg') no-repeat 100% 100%;
  background: url('../assets/img/background-img.png') no-repeat 100% 100%;
  // filter: blur(20px);
  // overflow: hidden;
  &.show-wild-map {
    background: url('../assets/img/map-bg-mianyang.png') no-repeat 100% 100%;
  }
  background-position: 100% 100% !important;
  background-size: 100% 100% !important;
}
.header {
  // height: 0.45rem;
  height: 10.99vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(2px);
  .logo {
    margin-left: 20px;
    margin-top: -10px;
    width: 0.9rem;
    height: 0.33rem;
    // margin-left: 0.18rem;
    object-fit: cover;
    background: url('../assets/img/nav&side/nav-logo-koala.png') no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
  .logo-text {
    margin: 0;
    margin-top: -8px;
    font-family: title;
    font-weight: normal;
    font-size: 42px;
    line-height: 42px;
    background: linear-gradient(to right, #f3f7ff, #a0a0a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    user-select: none;
  }
  .navs {
    margin: 0;
    padding: 0;
    width: 4rem;
    display: flex;
    list-style: none;
    .nav-item {
      margin-left: 0.16rem;
      width: 0.77rem;
      height: 0.19rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      &:first-child {
        margin-left: 0.25rem;
      }
      &.data {
        background-image: url('../assets/img/nav&side/nav-text-data.png');
      }
      &.data.active {
        background-image: url('../assets/img/nav&side/nav-selected-data.png');
      }
      &.manage {
        background-image: url('../assets/img/nav&side/nav-text-manage.png');
      }
      &.manage.active {
        background-image: url('../assets/img/nav&side/nav-selected-manage.png');
      }
      &.history {
        background-image: url('../assets/img/nav&side/nav-text-history.png');
      }
      &.history.active {
        background-image: url('../assets/img/nav&side/nav-selected-history.png');
      }
      &.audit {
        background-image: url('../assets/img/nav&side/nav-text-audit.png');
      }
      &.audit.active {
        background-image: url('../assets/img/nav&side/nav-selected-audit.png');
      }
    }
  }
  .right-area {
    width: 2.8rem;
    display: flex;
    justify-content: flex-end;
    .right-item {
      padding: 0 0.1rem;
      height: 0.3rem;
      position: relative;
      // background-color: rgba(19, 163, 163, 0.342);
      &::after {
        content: '';
        position: absolute;
        height: 0.21rem;
        width: 1px;
        right: 0;
        top: calc(50% - 0.1rem);
        background-color: #2f2f2f;
      }
      &.switch-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      &.user {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: #919191;
        &::after {
          display: none;
        }
      }
      .title {
        font-size: 16px;
        color: #919191;
      }
      .highlight-text {
        margin-top: 0.04rem;
        display: flex;
        align-items: center;
        color: rgb(223, 223, 223);
        font-size: 22px;
        font-family: 'din';
        font-weight: bold;
        img {
          width: 0.1rem;
          height: 0.1rem;
          margin-right: 0.05rem;
        }
      }
    }
  }
}
.content {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  // height: calc(100vh - 0.5rem);
  height: 88.99vh;
  .sidebar {
    width: 1.53rem;
    height: 100%;
    // background: rgba(31, 33, 173, 0.5);
    .el-menu {
      border: none;
      * {
        font-size: 18px;
        font-weight: bold;
      }
      ::v-deep .el-submenu .el-menu-item {
        padding-left: 0.52rem !important;
        height: 0.25rem;
        > span {
          opacity: 0.65 !important;
        }
      }
      ::v-deep .el-submenu.is-active {
        .el-menu-item.is-active > span {
          opacity: 1 !important;
        }
        .el-submenu__title {
          background: url('../assets/img/nav&side/nav-selected.png') no-repeat;
          background-size: 100% 100%;
        }
        .el-menu-item.is-active::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0.35rem;
          width: 100%;
          height: 5px;
          background: url('../assets/img/nav&side/submenu-line.png') no-repeat;
          transition: all 5s ease-in-out;
        }
      }
      ::v-deep .el-submenu .el-menu-item:hover,
      ::v-deep .el-submenu .el-menu-item:focus {
        background-color: transparent !important;
      }
      ::v-deep .el-submenu__title,
      .menu-item {
        display: flex;
        align-items: center;
        height: 0.38rem;
        padding: 0 !important;
        margin: 0 0.06rem !important;
        background: url('../assets/img/nav&side/nav-no-selected.png') no-repeat;
        background-size: 100% 100%;
        &:hover,
        &:focus {
          background-color: transparent !important;
        }
        &.is-active {
          background: url('../assets/img/nav&side/nav-selected.png') no-repeat;
          background-size: 100% 100%;
        }
        .nav-text {
          letter-spacing: 3px;
          padding-left: 0.1rem !important;
        }
      }
      .nav-text {
        position: relative;
        top: -0.025rem;
        letter-spacing: 3px;
      }
      .nav-icon {
        width: 0.22rem;
        width: 0.24rem;
        margin-top: -0.02rem;
        margin-left: 0.068rem;
        margin-right: 0.05rem;
      }
      ::v-deep .el-submenu__icon-arrow {
        top: 44% !important;
      }
    }
  }
  .router-view-container {
    position: relative;
    height: 100%;
    width: calc(100vw - 1.68rem);
    margin-right: 0.1rem;
    &.full {
      width: 100vw;
      margin: 0;
    }
    .router-container {
      position: relative;
      height: 100%;
    }
  }
}
.no-auth {
  position: absolute;
  box-sizing: border-box;
  right: 140px;
  top: 5px;
  width: 301px;
  height: 93px;
  padding-left: 60px;
  line-height: 93px;
  font-size: 24px;
  font-weight: bold;
  user-select: none;
  text-align: center;
  background: url('../assets/img/no-auth-area.png') no-repeat;
  background-size: 100% 100%;
}
</style>
