import service from '../../utils/request';

export default {
  // 布控分组查询
  fetchPersonGroup(params) {
    return service.get('/api/person/group/list', { params });
  },
  createPersonGroup(data) {
    return service.post('/api/person/group/create', data);
  },
  updatePersonGroup(data) {
    return service.post('/api/person/group/update', data);
  },
  deletePersonGroup(data) {
    return service.post('/api/person/group/remove', data);
  },
  fetchPerson(params) {
    return service.get('/api/person/info/list', { params });
  },
  fetchPersonDetail(params) {
    return service.get('/api/person/info/info', { params });
  },
  createPerson(data) {
    return service.post('/api/person/info/create', data);
  },
  updatePerson(data) {
    return service.post('/api/person/info/update', data);
  },
  deletePerson(data) {
    return service.post('/api/person/info/remove', data);
  },
  uploadPersonImage(data) {
    return service.post('/api/storage/upload_file_face', data, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  },
  importPerson(data) {
    return service.post('/api/person/common/person_info/upload', data);
  },
  importPersonFace(data) {
    return service.post('/api/person/common/person_face/upload', data, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  },
  exportPerson(data) {
    return service.post('/api/person/common/download', data, { responseType: 'blob' });
  },
  batchMovePerson(data) {
    return service.post('/api/person/info/move', data);
  },
  // 下载人员信息模板
  downloadPersonTemplate() {
    return service.get('/api/person/common/person_info/download_template', null, {
      responseType: 'blob',
    });
  },
  // 下载错误数据
  downloadPeopleError(data) {
    return service.post('/api/person/common/person_info/download_error', data, {
      responseType: 'blob',
    });
  },
  // // 区域树查询
  // fetchAreaTree(params) {
  //   return service.get('/api/core/area/find', { params });
  // },
  // // 区域树查询(只查询非叶子节点)
  // fetchAreaParentTree(params) {
  //   return service.get('/api/core/area/find/tree', { params });
  // },
  // createArea(data) {
  //   return service.post('/api/core/area/save', data);
  // },
  // updateArea(data) {
  //   return service.post('/api/core/area/update', data);
  // },
  // deleteArea(data) {
  //   return service.post('/api/core/area/remove', data);
  // },
  // fetchCameraList(params) {
  //   return service.get('/api/core/camera/list', { params });
  // },
  // createCamera(data) {
  //   return service.post('/api/core/camera/create', data);
  // },
  // updateCamera(data) {
  //   return service.post('/api/core/camera/modify', data);
  // },
  // deleteCamera(data) {
  //   return service.post('/api/core/camera/delete', data);
  // },
  // fetchCameraDetail(params) {
  //   return service.get('/api/core/camera/info', { params });
  // },
  // fetchCameraStream(params) {
  //   return service.get('/api/camera/account', { params });
  // },
  // refreshCamera(params) {
  //   return service.get('/api/camera/refresh/cover', { params });
  // },
  // bindAbility(data) {
  //   return service.post('/api/core/camera/binding_ability', data);
  // },
  // batchMoveCamera(data) {
  //   return service.post('/api/core/camera/batch_move', data);
  // },
  // importCamera(data) {
  //   return service.post('/api/core/camera/upload', data);
  // },
  // // 下载模板
  // downloadTemplate() {
  //   return service.get('/api/core/camera/upload_template', null, { responseType: 'blob' });
  // },
  // // 下载错误数据
  // downloadError(data) {
  //   return service.post('/api/core/camera/download_error', data, { responseType: 'blob' });
  // },
  // // 编辑摄像机基本信息
  // updateCameraBasic(data) {
  //   return service.post('/api/core/camera/base/modify', data);
  // },
  // // 编辑摄像机外部设备
  // updateCameraDevice(data) {
  //   return service.post('/api/core/camera/equipment/modify', data);
  // },
};
