/* eslint-disable */
/**
 * @Author target
 * @Date 2021/6/17 11:01
 * @Version 1.0
 */
import { Message } from 'element-ui';

let messageInstance = null;
const messageTypes = ['error', 'success', 'info', 'warning'];

const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};

messageTypes.map((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
      };
    }
    options.type = type;
    options.showClose = true;
    options.offset = 80;
    return resetMessage(options);
  };
});
export default resetMessage;
