import service from '../../utils/request';

export default {
  // 班次设置
  fetchClassList(params) {
    return service.get('/api/inspection/shift/list', { params });
  },
  fetchClassDetail(params) {
    return service.get('/api/inspection/shift/info', { params });
  },
  createClass(data) {
    return service.post('/api/inspection/shift/create', data);
  },
  updateClass(data) {
    return service.post('/api/inspection/shift/update', data);
  },
  deleteClass(data) {
    return service.post('/api/inspection/shift/remove', data);
  },
  // 巡检设置
  fetchPatrolList(params) {
    return service.get('/api/inspection/list', { params });
  },
  fetchPatrolDetail(params) {
    return service.get('/api/inspection/info', { params });
  },
  createPatrol(data) {
    return service.post('/api/inspection/create', data);
  },
  updatePatrol(data) {
    return service.post('/api/inspection/update', data);
  },
  deletePatrol(data) {
    return service.post('/api/inspection/delete', data);
  },
  // 人员巡检告警
  fetchPatrolAlarmList(params) {
    return service.get('/api/inspection/data/list', { params });
  },
  fetchPatrolAlarmDetail(params) {
    return service.get('/api/inspection/data/info', { params });
  },
  deletePatrolAlarm(params) {
    return service.get('/api/inspection/data/delete', { params });
  },
};
