import Vue from 'vue';
import ElementUI from 'element-ui';
import API from '@/api';
import func from '@/utils/func';
import highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';

import App from './App.vue';
import router from './router';
import store from './store';

import resetMessage from './utils/resetMessage';

import 'animate.css';

import 'amfe-flexible';

import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss';
// import '@/theme/dark/style.scss';
import '@/styles/_element-variables.scss';

Vue.prototype.API = API;

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.prototype.$message = resetMessage;

Vue.prototype.$func = func;

Vue.prototype.$downloadFile = (data, fileName) => {
  if (!data) {
    return;
  }
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

highcharts3d(highcharts);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
