import service from '../../utils/request';

export default {
  // 查询磁盘容量
  fetchStorageDisk(params) {
    return service.get('/api/storage/disk/find', { params });
  },
  // 获取存储策略
  fetchStorage(params) {
    return service.get('/api/storage/find_storage', { params });
  },
  // 更新存储策略
  updateStorage(data) {
    return service.post('/api/storage/create_storage', data);
  },
};
