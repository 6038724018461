import service from '../../utils/request';

export default {
  login(data) {
    return service.post('/api/user/login', data);
  },
  loginByToken(params) {
    return service.get('/api/user/hua/login', { params });
  },
  // 获取所有能力-包含算法策略列表
  fetchAllAbility(params) {
    return service.get('/api/core/warning_with_strategy/list', { params });
  },
  // 获取所有能力-包含算法策略列表
  fetchAllAbilityInGroup(params) {
    return service.get('/api/core/warning/list_by_group', { params });
  },
  uploadAlertLivePhoto(data) {
    return service.post('/api/storage/upload_file_alarm', data, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  },
};
