/* eslint-disable */
import axios from 'axios';
import resetMessage from '@/utils/resetMessage';
// import store from '../store';
import router from '@/router';

// const Message = new DonMessage();
// var CancelToken = axios.CancelToken;
// var source = CancelToken.source();
// create an axios instance
const service = axios.create({
  baseURL: '/', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 0, // request timeout
  validateStatus(status) {
    switch (status) {
      case 400:
        resetMessage.error('请求出错');
        break;
      case 401:
        sessionStorage.removeItem('token');
        resetMessage.error({
          message: '授权失败，请重新登录',
          // onClose() {
          //   store.dispatch('user/logout').then(() => {
          //     // 用户登录界面提示
          //     // Cookies.set("point", 401);
          //   });
          // },
        });

        break;
      case 412:
        sessionStorage.removeItem('token');
        resetMessage.warning({
          message: '授权失败，请重新登录',
        });
        break;
      case 403:
        resetMessage.warning({
          message: '拒绝访问',
        });
        break;
      case 404:
        resetMessage.error({
          message: '请求错误,未找到该资源',
        });
        break;
      case 500:
        resetMessage.warning({
          message: '服务端错误',
        });
        break;
      default:
        break;
    }
    return status >= 200 && status < 300;
  },
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers['token'] = sessionStorage.getItem('token');
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // if the custom code is not 200, it is judged as an error.
    // 如果请求路径以 group2 开头，代表请求的是文件服务器，直接返回 url
    // console.log('response:', response);
    if (response.request.responseURL.indexOf('/group2') > -1) {
      return response.data;
    }
    if (response.config.responseType === 'arraybuffer') {
      return response;
    }
    if (response.config.responseType === 'blob') {
      return response;
    }
    const res = response.data;
    if (res.code === 401 || res.code === 402) {
      resetMessage({
        message: res.desc,
        type: 'error',
        duration: 3 * 1000,
      });
      router.push('/login');
      return res;
    } else if (res.code === 706) {
      // 上传文件报错的错误码, 将对象抛错
      throw res;
    } else if (res.code === 1003) {
      // 登录过期抛错
      resetMessage({
        message: response.data.desc,
        type: 'error',
        duration: 3 * 1000,
      });
      sessionStorage.removeItem('token');
      router.push('/login');
      throw new Error(res);
    } else if (res.code === 1004) {
      // 无权限抛错
      resetMessage({
        message: response.data.desc,
        type: 'error',
        duration: 3 * 1000,
      });
      router.push('/404');
      throw new Error(res);
    } else if (res.code !== 200) {
      // 处理非200的返回, 抛错并提示文字
      resetMessage({
        message: response.data.desc,
        type: 'error',
        duration: 3 * 1000,
      });
      throw new Error(res);
    } else {
      return res;
    }
  },
  (error) => {
    // 登陆过期, 请求被拒绝
    if (error.response.status == 401) {
      resetMessage({
        message: '登录失效, 请重新登录!',
        type: 'error',
        duration: 3 * 1000,
      });
      router.push('/login');
      return;
    }
    // 特殊处理blob类型的返回, 格式化其data为字符串并输出文字提示
    if (error.response.status !== 200 && error.config.responseType === 'blob') {
      var blob = new Blob([error.response.data], {
        type: 'text/plain',
      });
      // 将Blob 对象转换成字符串
      var reader = new FileReader();
      reader.readAsText(blob, 'utf-8');
      reader.onload = () => {
        const obj = {};
        try {
          let obj = JSON.parse(reader.result);

          resetMessage({
            message: obj.desc,
            type: 'error',
            duration: 3 * 1000,
          });
        } catch (e) {
          debugger;
        }
      };
    } else {
      resetMessage({
        message:
          error.response.data && error.response.data.desc
            ? error.response.data.desc
            : '请求出错, 请联系开发人员!',
        type: 'error',
        duration: 3 * 1000,
      });
    }
    // if (error.response) {
    //   if (error.response.data.code === 401) {
    //     resetMessage({
    //       message: error.response.data.desc,
    //       type: 'error',
    //       duration: 3 * 1000,
    //     });
    //   } else {
    //     resetMessage({
    //       message: error.response.data.desc,
    //       type: 'error',
    //       duration: 3 * 1000,
    //     });
    //   }
    // } else {
    //   resetMessage({
    //     message: error.desc,
    //     type: 'error',
    //     duration: 3 * 1000,
    //   });
    // }
    return Promise.reject(error);
  },
);

export default service;
