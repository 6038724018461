import service from '../../utils/request';

export default {
  fetchMessageConfig(params) {
    return service.get('/api/push/find_message_strategy', { params });
  },
  updateMessageConfig(data) {
    return service.post('/api/push/create_message_strategy', data);
  },
  fetchThirdConfig(params) {
    return service.get('/api/push/third-system/find', { params });
  },
  updateThirdConfig(data) {
    return service.post('/api/push/third-system/save', data);
  },
  // 特殊的上传告警事件图片的接口, 与通常上传所返回的内容不一致
  uploadAlertImage(data) {
    return service.post('/api/push/third-system/save', data);
  },
  // 处理告警事件
  handleAlert(data) {
    return service.post('/api/push/third-system/save', data);
  },
};
