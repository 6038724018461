import service from '../../utils/request';

export default {
  // 查询历史数据
  fetchAlertList(params) {
    return service.get('/api/alarm/history', { params });
  },
  handleAlert(data) {
    return service.post('/api/alarm/process', data);
  },
  deleteAlert(data) {
    return service.post('/api/alarm/remove', data);
  },
  fetchAlertDetail(params) {
    return service.get('/api/alarm/info', { params });
  },
  fetchStackChartsByAI(params) {
    return service.get('/api/statistic/report/alarmNumStatistical', { params });
  },
  fetchStackChartsByTrend(params) {
    return service.get('/api/statistic/report/alarmTypeStatistical', { params });
  },
  fetchRankData(params) {
    return service.get('/api/statistic/report/alarmAreaRank', { params });
  },
  fetchRingsCharts(params) {
    return service.get('/api/statistic/report/alarmProcessingRate', { params });
  },
  fetchRoseCharts(params) {
    return service.get('/api/statistic/report/alarmProcessingRate', { params });
  },
  fetchDailyData(params) {
    return service.get('/api/statistic/report/alarmCount', { params });
  },
  fetchAllAI(params) {
    return service.get('/api/statistic/warning/find', { params });
  },
  // 下载导出报表文件
  downloadCharts(params) {
    return service.post('/api/statistic/report/download', params, { responseType: 'blob' });
  },
  fetchDashboardList(params) {
    return service.get('/api/alarm/dail_plate/list_with_page', { params });
  },
  deleteDashboardData(data) {
    return service.post('/api/alarm/dail_plate/remove', data);
  },
  exportDashboardData(params) {
    return service.post('/api/alarm/dail_plate/download', params, { responseType: 'blob' });
  },
};
